import http from '@/api/http';

const updateRefreshToken = () => {
	return http.post('/auth/refresh');
};

const socialLogin = (token: string, fcmToken: any) => {
	return http.post('/auth/social', {
		token,
		fcmToken,
	});
};

export default {
	updateRefreshToken,
	socialLogin,
};
